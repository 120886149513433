<template>
  <v-container class="fill-height">
    <v-row>
      <v-col cols="12">
        <v-img src="./not_found.svg" contain max-height="40vh"></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
name: 'NotFound'
}
</script>

<style scoped>

</style>
